<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-2">
        For each of the following compounds, select the appropriate melting point range from the
        provided list.
      </p>

      <v-simple-table>
        <thead>
          <tr v-for="question in questions" :key="question.vModel" class="mb-0 mt-0">
            <td>
              <stemble-latex :content="question.text" />
            </td>
            <td>
              <v-select
                v-model="inputs[question.vModel]"
                class="my-1"
                :items="options"
                item-text="text"
                item-value="value"
                style="display: inline-block"
              >
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
              </v-select>
            </td>
          </tr>
        </thead>
      </v-simple-table>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'ChemUCI51LBA1Q4',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
        input3: null,
        input4: null,
      },
      questions: [
        {
          text: 'Fluorene: ',
          vModel: 'input1',
        },
        {
          text: '3-methylbenzoic acid: ',
          vModel: 'input2',
        },
        {
          text: '2-chlorobenzoic acid: ',
          vModel: 'input3',
        },
        {
          text: '3-chlorobenzoic acid: ',
          vModel: 'input4',
        },
      ],
      options: [
        {text: '$\\ce{111-113}^{\\circ}\\ce{C}$', value: 'a'},
        {text: '$\\ce{116-117}^{\\circ}\\ce{C}$', value: 'b'},
        {text: '$\\ce{142-143}^{\\circ}\\ce{C}$', value: 'c'},
        {text: '$\\ce{154-157}^{\\circ}\\ce{C}$', value: 'd'},
      ],
    };
  },
};
</script>
